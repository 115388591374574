import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
} from '@remix-run/react';
import styles from './tailwind.css?url';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import bgImage from './assets/background-pattern.jpg';
import { useEffect } from 'react';

export const meta = () => {
  return [
    { title: 'DocsAutomator' },
    {
      name: 'description',
      content: 'Professional document automation using Google Docs.',
    },
  ];
};

export const links = () => [{ rel: 'stylesheet', href: styles }];

export function ErrorBoundary() {
  const error = useRouteError();

  return (
    <div className="min-h-screen flex flex-col justify-center items-center gap-4">
      <h1 className="text-xl font-bold">Oops, something went wrong!</h1>
      <p>Please try reloading and reach out to support if the error persists</p>
      <p>Error: {error ? error.message : 'Something went wrong'}</p>
    </div>
  );
}

export function Layout({ children }) {
  useEffect(() => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = 'ecf4cd12-12dd-4250-a318-32c4b1092400';

    const s = document.createElement('script');
    s.src = 'https://client.crisp.chat/l.js';
    s.async = true;
    if (process.env.NODE_ENV === 'production') {
      document.getElementsByTagName('head')[0].appendChild(s);
    }

    const moduleScript = document.createElement('script');
    moduleScript.type = 'module';
    moduleScript.src =
      'https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js';
    document.body.appendChild(moduleScript);
  }, []);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Ubuntu+Mono&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css"
        />
        <script src="https://js.stripe.com/v3/pricing-table.js"></script>
        <script src="https://kit.fontawesome.com/e8faad09b1.js"></script>
        <script
          src="https://app.bentonow.com/20957ebef171cecf9575f72cc925adb8.js"
          async
          defer
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');`,
          }}
        />
        <script
          async
          src="https://affiliates.docsautomator.co/rw.js"
          data-rewardful="af394e"
        ></script>
      </head>
      <body
        style={{ backgroundImage: `url(${bgImage})` }}
        className="min-h-screen bg-fixed antialiased"
      >
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

function App() {
  return <Outlet />;
}

export default App;
